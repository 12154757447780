.Site-Hero {
  width: 100%;
  background-color: #000;
  display: flex;
  align-items: center;
  margin-top: -64px;
  height: 100vh;
  position: relative;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0; } }
.Site-HeroContent {
  position: relative;
  z-index: 100; }
.Hero-Offer-Container {
  display: flex;
  justify-content: center; }
.Hero-Offer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #fff;
  .Offer-Title {
    width: 100%;
    font-size: 52px;
    font-weight: 300;
    span {
      font-weight: 700; } }
  .Offer-Description {
    font-size: 16px;
    margin-bottom: 48px; }
  .Offer-Buttons {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 24px; } }
.Hero-SocialBlock {
  position: absolute;
  top: 50%;
  right: 32px;
  transform: translateY(-50%);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  font-size: 24px;
  justify-items: center;
  color: #fff;
  .Icon {
    cursor: pointer;
    transition: all .2s ease-in-out;
    color: #fff;
    &:hover {
      color: var(--activeGray); } } }

@media screen and ( max-width: 1024px ) {
  .Hero-SocialBlock {
    top: auto;
    bottom: 16px;
    left: 50%;
    transform: none;
    grid-template-columns: repeat(3, max-content);
    align-items: center;
    transform: translateX(-50%);
    right: auto; }
  .Hero-Offer {
    .Offer-Title {
      font-size: 42px;
      line-height: 52px; }
    .Offer-Buttons {
      grid-template-columns: 1fr;
      .Button {
        width: 100%; } } } }
