.ProductTile {
  width: 100%;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  line-height: 16px;
  .PhotoTile-Markers {
    right: 0;
    left: auto; }
  .DefaultButton {
    background-color: rgba(0,0,0, 0.4);
    color: #ffffff;
    transition: all 0.5s ease;
    cursor: pointer;
    z-index: 3;
    &:not(:last-child) {
      margin-bottom: 8px; }
    &:hover {
      background-color: #000; } }
  .NotPublished {
    display: inline-block;
    width: auto;
    top: 0;
    position: absolute;
    left: 0;
    background-color: var(--notyColor);
    padding: 0px 8px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .ProductTile-Avatar {
    width: 100%;
    height: 340px;
    position: relative;
    align-self: start;
    margin-bottom: 16px;
    span {
      width: 100%;
      height: 100%;
      border-radius: 0px !important; }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center; }
    .ProductTile-NoAvatar {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
      background-color: var(--darkGray); } }
  .ProductTile-Info {
    margin-top: 32px;
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    .Button {
      width: 100%;
      align-self: end; } }
  .Title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 16px; }
  .Price {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-weight: 700;
    .Icon {
      margin-right: 4px; }
    span {
      color: var(--darkGray); } }
  .VideoReview {
    display: inline-flex;
    align-items: center;
    .Icon {
      margin-right: 8px; } }
  .VideoReview-Button {
    padding: 4px 8px;
    background-color: #EE8F00;
    color: #fff;
    border-radius: 4px; }

  .Colors, .Sizes {
    display: inline-flex;
    span {
      &:not(:last-child) {
        margin-right: 8px; } }
    .LineTitle {
      color: var(--darkGray); } }
  .Colors-Skeleton {
    display: grid;
    grid-template-columns: 60px repeat(4, 16px);
    grid-gap: 8px; }
  .ColorSquare {
    width: 16px;
    height: 16px;
    display: block;
    border-radius: 4px;
    cursor: pointer; }
  .SizeTitle {
    color: #fff;
    text-transform: uppercase; }
  .Details {
    width: 100%;
    color: var(--activeGray);
    border-bottom: 1px var(--darkGray) solid;
    padding-bottom: 12px;
    margin-bottom: 8px; }
  .Details-Skeleton {
    border-bottom: 1px #232323 solid; } }

.ProductTile-Buttons {
  // padding: 0px 16px
  justify-self: center;
  width: 100%;
  margin-top: auto;
  align-self: last baseline;
  .Button {
    width: 100%; } }

.ProductTile-Thumbnails-Skeleton {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 12px; }
.ProductTile-Thumbnails {
  width: 100%;
  height: 54px;
  max-width: 252px;
  align-self: start;
  margin-left: 50%;
  transform: translateX(-50%);
  img {
    width: 54px !important;
    height: 54px !important;
    object-fit: cover;
    object-position: top center; }
  .Carousel-Arrow {
    width: 16px;
    height: 24px;
    .Icon {
      font-size: 16px; }
    &:hover {
      background-color: rgba(0,0,0,.6); } }
  .Arrow-Left {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .Arrow-Right {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    right: 0; } }

@media screen and ( max-width: 768px ) {
  .ProductTile {
    .ProductTile-Avatar {
      height: 520px; }
    .ProductTile-Buttons {
      display: inline-flex;
      justify-content: center;
      .Button {
        width: auto;
        min-width: 252px;
        display: inline-flex; } } } }
