.ProjectPage {
  width: 100%;
  background-color: #fff;
  padding: 64px;
  padding-top: 128px;
  margin-top: -64px; }
.Project-Status {
  width: 100%;
  padding: 16px; }
.Project-Status_not_published {
  background-color: var(--failColor);
  color: var(--notyColor);
  a {
    color: #000 !important;
    text-decoration: underline !important; } }
.Project-Status_published {
  background-color: var(--successColor);
  color: #27C043;
  a {
    color: #000 !important;
    text-decoration: underline !important; } }

.ProjectPage-Container {
  max-width: 1440px;
  width: 100%;
  display: grid;
  grid-template-columns: 340px 1fr;
  grid-gap: 64px;
  margin-left: 50%;
  transform: translateX(-50%);
  .LeftSide {
    align-self: start;
    position: sticky;
    top: 128px;
    left: 64px; } }
.Project-Details {
  background-color: #000;
  padding: 24px;
  color: #fff;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  align-self: start;
  position: sticky;
  top: 128px;
  left: 64px;
  .Field-Checkbox {
    color: #fff;
    margin-bottom: 0; }
  h3 {
    font-size: 24px;
    line-height: 34px;
    position: relative;
    padding-bottom: 8px;
    font-weight: 700;
    &::after {
      content: '';
      display: block;
      width: 40px;
      height: 2px;
      background-color: #fff;
      border-radius: 2px;
      position: absolute;
      bottom: 0; } }
  .Buttons {
    padding-top: 24px;
    border-top: 1px solid var(--darkGray); } }

.BlockData {
  h5 {
    color: var(--darkGray);
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 24px; }
  .BlockData-Content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    span {
      color: var(--darkGray); } } }

.AuthorTile {
  width: 100%;
  display: flex;
  align-items: center;
  img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    object-position: top center;
    margin-right: 12px;
    flex-shrink: 0; }
  .Title {
    font-size: 16px;
    font-weight: 700; }
  .Description {
    color: var(--darkGray); } }

.Project-Content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 64px;
  grid-template-rows: min-content;
  .Content-Title {
    font-size: 48px;
    font-weight: 700; }
  .Content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 64px; }
  .Content-Block {
    width: 100%;
    p {
      margin-top: 24px;
      font-size: 24px;
      text-transform: uppercase; } }
  .Content-PhotoGrid {
    display: grid;
    // grid-template-columns: 1fr
    grid-template-columns: repeat(auto-fit, minmax(240px, 320px));
    grid-auto-rows: 420px;
    grid-gap: 24px;
    img {
      object-fit: cover;
      object-position: center center; }
    .PhotoTile-Skeleton {
      min-height: 300px; } } }
.EmptyField {
  width: 100%;
  height: 360px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 48px;
  position: relative;
  transition: all .2s ease-in-out;
  appearance: none;
  outline: none;
  .Field {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    video {
      object-fit: contain; } } }

@media screen and ( max-width:  1200px) {
  .Project-Content {
    .Content {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 64px; } } }

@media screen and ( max-width: 768px ) {
  .ProjectPage {
    width: 100%;
    padding-top: 96px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 48px; }
  .ProjectPage-Container {
    grid-template-columns: 1fr;
    .LeftSide {
      order: 1; }
    .Project-Content {
      order: 0; } }
  .Project-Content {
    grid-gap: 48px;
    .Content {
      display: grid;
      grid-template-columns: 1fr; }
    .Content-Title {
      text-align: center; } }
  .Project-Details {
    .Buttons {
      text-align: center; } } }
