.ProductManager {
  width: 800px;
  .PopUp-Content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px; }
  .Project-Status {
    margin-top: 24px; } }
.ProductManager-Container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
  align-items: start;
  .PhotoManager-PhotoGrid {
    grid-gap: 24px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 160px; }
  .PhotoTile-OptionsContainer {
    grid-template-columns: repeat(2, 24px); }
  .Option {
    width: 24px;
    height: 24px;
    font-size: 12px; }
  .EmptyField {
    height: 250px; } }
.ProductManager-Section {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  align-items: start;
  grid-template-rows: max-content;
  .Title {
    font-size: 18px;
    text-transform: uppercase;
    color: #000; } }

.ProductManager-MediaSection {
  overflow-y: auto;
  max-height: calc(100vh - 128px - 48px - 40px - 48px);
  .Title {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10; } }

.ProductManager-Form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  .Field {
    border: 1px var(--lightGray) solid;
    box-sizing: border-box !important; }
  .DropdownBox-Container {
    top: 45px !important;
    border: 1px var(--lightGray) solid; } }
