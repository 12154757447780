.PrivacyPolicy {
  width: 100%;
  padding: 64px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  ul {
    list-style: disc;
    padding-left: 16px; }
  h4 {
    font-size: 24px; } }
