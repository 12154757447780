.Site-Nav {
  width: 100%;
  height: 64px;
  background-color: rgba(0,0,0, 0.7);
  padding: 0px 32px;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-gap: 32px;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 200;
  transition: all 0.4s ease;
  color: #fff;
  .Logo {
    flex-shrink: 0; } }
.Site-Nav_theme_transparent {
  background: none; }
.Site-Nav-Menu {
  display: grid;
  grid-template-columns: repeat(7, max-content);
  grid-gap: 32px;
  align-items: center;
  justify-self: end;
  padding-right: 32px;
  border-right: 2px solid #fff; }

.Site-NavLink {
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  &:hover {
    color: var(--activeGray); }
  svg {
    transition: all 0.3s ease; } }

.Site-NavLink-Arrow {
  font-size: 14px;
  color: var(--descriptionDark);
  margin-left: 8px; }
.Site-Nav-Controlls {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-gap: 40px;
  align-items: center;
  justify-self: end;
  .Site-NavLink {
    padding: 0; } }

@media screen and ( max-width: 1024px ) {
  .Site-Nav {
    padding: 0px 16px; }
  .Site-Nav-Menu {
    display: none;
    position: absolute;
    top: 64px;
    left: 0;
    grid-template-columns: 1fr;
    border-right: 0px;
    padding: 16px;
    background-color: rgba(0,0,0, 0.7);
    width: 100%; } }
