.Services {
  background-color: #000;
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .Portfolio-NavLink {
    &::after {
      background-color: var(--lightGray); }
    &:hover {
      color: #fff; } }
  .Portfolio-NavLink_theme_active {
    color: var(--lightGray); } }
.Services-Container {
  display: grid;
  grid-gap: 128px;
  max-width: 1000px;
  width: 100%;
  padding: 0px 16px;
  justify-content: center;
  .InfoBlock {
    width: 100%;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    line-height: 24px;
    align-self: center;
    h5 {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 8px;
      padding: 0; }
    .InfoBlock-Buttons {
      display: inline-block;
      margin-top: 8px; } }
  .Photo {
    position: relative;
    display: inline-block;
    z-index: 1; }
  .Photo_bordered, .Photo_layered {
    background-size: cover;
    position: relative;
    background-position: center center;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px var(--darkGray) solid;
      right: -32px;
      top: 32px;
      z-index: -1; } }
  .Photo_layered {
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: var(--lightGray);
      left: -32px;
      top: -32px;
      z-index: -1; } } }
.Services-VideoAndPhoto, .Services-Photo {
  grid-template-columns: repeat(2, max-content);
  .InfoBlock {
    order: 0; }
  .Photo {
    order: 1; }
  .Photo2 {
    display: none; } }

@media screen and ( min-width: 1400px ) {
  .Services-VideoAndPhoto, .Services-Photo {
    grid-template-columns: repeat(3, max-content);
    .InfoBlock {
      order: 1; }
    .Photo1 {
      order: 0; }
    .Photo2 {
      order: 2;
      display: block; } } }

@media screen and ( min-width:  768px) {
  .Services-Container {
    .InfoBlock {
      max-width: 400px; } } }

@media screen and ( max-width: 768px ) {
  .Services-Container {
    grid-template-columns: 1fr !important;
    grid-gap: 64px; }

  .InfoBlock-Buttons {
    text-align: center; }
  .Photo2, .Photo3 {
    display: none !important; }
  .Photo_bordered, .Photo_layered {
    &::after {
      display: none; } } }

@media screen and ( max-width: 768px ) {
  .Photo {
    .Photo_bordered, .Photo_layered {
      width: 100% !important; } } }
