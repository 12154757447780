.ContactUs-Form {
  width: 100%;
  max-width: 400px;
  color: #fff;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  grid-template-rows: min-content;
  align-self: start;
  h5 {
    font-size: 24px;
    margin: 0;
    padding: 0;
    line-height: 24px; }
  p {
    font-size: 14px;
    color: var(--darkGray); }
  .Form-Fields {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px; }
  .ContactUsForm-Buttons {
    display: inline-block; }
  .PrivacyPolicy-Link {
    margin-bottom: 16px;
    a {
      color: var(--activeGray);
      &:hover {
        text-decoration: underline !important; } } } }

@media screen and ( max-width: 576px ) {
  .ContactUs-Container {
    justify-items: center; }
  .ContactUs-Form {
    h5, p, form {
      text-align: center; } } }
