.Team {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to right, #ffffff 0% 10%, #F6F7F8 10% 90%, #ffffff 90% 100%); }
.Team-Container {
  width: 100%;
  margin-top: 64px;
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 128px;
  align-items: center;
  justify-content: center; }
.TeamTiles {
  display: grid;
  grid-template-columns: 320px;
  grid-gap: 56px;
  grid-template-rows: min-content;
  &:last-child {
    margin-top: 64px; } }
.Team-Info {
  max-width: calc(768px - 32px);
  width: 100%;
  padding: 260px 64px;
  background-color: #000;
  font-size: 14px;
  line-height: 26px;
  color: #fff;
  img {
    width: 100%;
    margin-bottom: 48px; }

  h5 {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 12px;
    position: relative;
    margin-bottom: 24px;
    &::after {
      content: '';
      display: block;
      width: 40px;
      height: 2px;
      background-color: #fff;
      border-radius: 2px;
      position: absolute;
      bottom: 0; } }
  p {
    margin-bottom: 24px; } }
.TeamTile {
  width: 320px;
  height: 420px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .TeamTile-Info {
    padding: 24px;
    .Title {
      font-size: 22px;
      color: #fff;
      margin-bottom: 0; }
    .Description {
      font-size: 14px;
      color: #fff; } }
  .TeamTile-Social {
    margin-top: 12px;
    display: grid;
    grid-template-columns: repeat(3, 24px);
    grid-gap: 12px;
    .Social-Block {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-color: #000;
      font-size: 14px;
      transition: all .2s ease-in-out;
      &:hover {
        background-color: var(--activeGray); } } } }

@media screen and ( min-width: 1700px) {
  .TeamContainer {
    grid-template-columns: repeat(3, max-content); } }

@media screen and ( max-width: 1700px) {
  .Team-Container {
    margin-top: 48px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 48px;
    padding: 0px 16px; }
  .Team-Info {
    width: 100%;
    padding: 48px 16px;
    .TeamInfo-Buttons {
      text-align: center; } }
  .TeamTile {
    width: 100%;
    height: 300px;
    .TeamTile-Info {
      background-color: rgba(0,0,0, 0.3); } }
  .TeamTiles {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 48px;
    &:last-child {
      margin-top: 0; } }
  .AnotherTiles {
    display: none; } }

@media (min-width: 1200px) and ( max-width:  1700px) {
  .TeamTile {
    height: 420px; } }

@media screen and ( max-width: 768px ) {
  .Team-Container {
    grid-template-columns: 1fr; }
  .TeamTile {
    width: 100%;
    height: 480px; }
  .AnotherTiles {
    display: block; } }

@media screen and ( max-width: 576px ) {
  .TeamTiles {
    grid-template-columns: 1fr;
    grid-gap: 48px;
    &:last-child {
      margin-top: 0; } } }
