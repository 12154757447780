.Carousel {
  position: relative; }
.Carousel-DotsContainer {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  height: 12px;
  .Carousel-Dot {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: var(--accentPrimary);
    border: 1px #fff solid;
    cursor: pointer;
    appearance: none;
    outline: none;
    display: block;
    &:not(:last-child) {
      margin-right: 8px; }
    &:hover {
      background-color: var(--brandSecondary); } }
  .glide__bullet--active {
    background-color: var(--brandSecondary); } }
.Carousel-Arrow {
  position: absolute;
  width: 32px;
  height: 48px;
  top: 50%;
  border: 0px;
  background-color: rgba(0,0,0,.3);
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  outline: none;
  .Icon {
    font-size: 24px; }
  svg {
    color: #fff; }
  &:hover {
    background-color: rgba(0,0,0,.6); } }
.Arrow-Left {
  left: 0; }
.Arrow-Right {
  right: 0; }
