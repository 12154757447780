.Footer {
  padding: 32px 0px;
  text-align: center;
  color: var(--darkGray);
  font-size: 12px;
  width: 100%;
  background-color: #fff; }
.bh-widget-page-wrapper {
  .bh-widget-page__link {
    font-size: 0 !important;
    border: 0px !important; } }
