.StatusMessage {
  padding: 4px 16px;
  display: flex;
  align-items: center; }

.StatusMessage_theme_success {
  background: var(--successColor);
  color: #27C043; }

.StatusMessage_theme_fail {
  background: var(--failColor);
  color: var(--notyColor);
  border-left: 2px var(--notyColor) solid; }
