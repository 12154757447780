@import "fonts";
@import "libs";

 :root {
  --defaultFont: "Comfortaa", cursive;
  --activeGray: #A9ABBC;
  --darkGray: #6E737D;
  --lightGray: #D1D5DE;
  --notyColor: #F34375;
  --successColor: #E4FFE9;
  --failColor: #FFDBE5; }

body {
  font-size: 14px;
  font-weight: 400;
  min-width: 320px;
  position: relative;
  line-height: 1.65;
  font-family: var(--defaultFont);
  color: #000;
  background-color: #000; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

input, button, textarea {
  outline: none;
  appearance: none; }

a {
  text-decoration: none !important; }
p {
  margin: 0;
  padding: 0; }
.DefaultSection {
  padding: 64px 0px; }
.NoInfoRow {
  width: 100%;
  text-align: center;
  grid-column: 1/-1; }

@media screen and ( max-width: 576px ) {
  .DefaultSection {
    padding: 48px 0px; } }
