.ProjectTile {
  &:hover {
    img {
      transform: scale(1.1); }
    .Project-Info {
      .Title {
        &::after {
          left: 0; } } } }

  .ProjectTile-Img {
    width: 100%;
    height: 390px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
      transition: all .2s ease-in-out; }
    span {
      width: 100%;
      height: 100%; } }
  .Project-Info {
    padding-top: 16px;
    .Title {
      font-size: 18px;
      line-height: 28px;
      color: #000;
      font-weight: 700;
      position: relative;
      overflow: hidden;
      &::after {
        content: '';
        display: block;
        width: 60px;
        height: 2px;
        background-color: #000;
        border-radius: 2px;
        position: absolute;
        transition: all .2s ease-in-out;
        bottom: 0;
        left: -60px; } }

    .TechInfo {
      font-size: 12px;
      margin-top: 4px;
      color: var(--darkGray); }
    .TechInfo-Category, .TechInfo-Author {
      transition: all .2s ease-in-out;
      &:hover {
        color: #000 !important; } }
    .TechInfo-Author {
      color: var(--activeGray); } } }

@media screen and ( max-width: 1024px ) {
  .ProjectTile {
    .ProjectTile-Img {
      height: 520px; } } }
